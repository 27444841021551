import React, { useState, useEffect } from "react";
import CustomBreadcrumb from "../../components/breadcrumb";
import Attendance from "../leaves/Attendance";
import LeaveHistory from "../leaves/LeaveHistory";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  Button
} from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import TotalLeaves from "./../../assets/images/totalLeave.svg";
import CompOff from "./../../assets/images/compOff.svg";
import RH from "./../../assets/images/rh.svg";
import LWP from "./../../assets/images/lwp.svg";
import Axios from "axios";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ForwardSharpIcon from "@material-ui/icons/ForwardSharp";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${"index"}`
  };
}

const LeaveDashboard = props => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let [appliedLeaves, setAppliedLeaves] = useState([]);

  // get self applied leaves
  async function getAppliedLeavesData(filterParams = {}) {
    try {
      filterParams["scope"] = "my";
      let { data } = await Axios.post("/api/leaves/all/leaves", filterParams);
      setAppliedLeaves(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  let [leavesData, setLeavesData] = useState({
    availableLeaves: []
  });

  async function getLeavesData() {
    try {
      let { data } = await Axios.get(
        `/api/leaves/apply/info/employee/${props.user.employeeCode}`
      );
      setLeavesData(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  let [deleteStatus, setDeleteStatus] = useState(false);
  let [alert, setAlert] = useState({
    open: false,
    message: "",
    duration: 3000,
    color: ""
  });

  useEffect(function() {
    getAppliedLeavesData();
    getLeavesData();
  }, []);

  let totalAvailableEL = 0,
    totalAvailableCOF = 0,
    totalAvailableRH = 0,
    totalAvailableML = 0,
    appliedLWP = 0,
    appliedOD = 0,
    appliedRH = 0,
    appliedEL = 0,
    appliedML = 0,
    appliedCOF = 0;

  let leavesTableData = [];
  leavesData.availableLeaves.forEach(leave => {
    if (leave.title === "COF") totalAvailableCOF += leave.balance;
    if (leave.title === "EL") totalAvailableEL += leave.balance;
    if (leave.title === "RH") totalAvailableRH += leave.balance;
    if (leave.title === "ML") totalAvailableML += leave.balance;
  });

  appliedLeaves.forEach(leave => {
    if (leave.leaveType.title === "LWP") appliedLWP++;
    if (leave.leaveType.title === "OD") appliedOD++;
    if (leave.leaveType.title === "RH") appliedRH++;
    if (leave.leaveType.title === "EL") appliedEL++;
    if (leave.leaveType.title === "COF") appliedCOF++;
    if (leave.leaveType.title === "ML") appliedML++;

    leavesTableData.push({
      from: `${CustomDateFormat({ utc_date: leave.startDate, isDay: true })} 
          ${CustomDateFormat({ utc_date: leave.startDate, isDay: false })}`,
      to: `${CustomDateFormat({ utc_date: leave.endDate, isDay: true })} 
      ${CustomDateFormat({ utc_date: leave.endDate, isDay: false })}`,
      empCode: props.user.employeeCode,
      leaveType: leave.leaveType.title,
      count: leave.noOfDays,
      reason: leave.reason,
      status: leave.status,
      leaveId: leave._id
    });
  });

  function deleteLeave(leaveId) {
    if (window.confirm("Do you want to delete this leave?")) {
      Axios.delete(`/api/leaves/delete/${leaveId}`).then(function(res) {
        setAlert({
          ...alert,
          message: res.data.message,
          color: "success",
          open: true
        });
        getAppliedLeavesData();
      });
    }
  }

  function handleAppliedLeaveFilterSubmit(params) {
    let filterParams = params;
    getAppliedLeavesData(filterParams);
  }

  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Leave Dashboard</h1>
      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Leave", link: "/leave/dashboard" }
          ]}
        />
      </div>
      <Grid container spacing={2} className="mB15">
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                EL
              </Typography>
              <Typography component="h4">{`${appliedEL}/${totalAvailableEL}`}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={TotalLeaves} alt="Leaves" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                Comp-off
              </Typography>
              <Typography component="h4">{`${appliedCOF}/${totalAvailableCOF}`}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={CompOff} alt="CompOff" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                Restr. Holidays
              </Typography>
              <Typography component="h4">{`${appliedRH}/${totalAvailableRH}`}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={RH} alt="RH" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                LWP
              </Typography>
              <Typography component="h4">{appliedLWP}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={LWP} alt="LWP" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                OD
              </Typography>
              <Typography component="h4">{appliedOD}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={TotalLeaves} alt="Leaves" />
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="employeeDetailsCard">
            <Typography component="div">
              <Typography variant="h6" component="p">
                ML
              </Typography>
              <Typography component="h4">{appliedML}</Typography>
            </Typography>
            <Typography component="div" className="cardIcon">
              <img src={TotalLeaves} alt="Leaves" />
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mB15">
        <Grid item xs={12}>
          <div className="btnConatinerR">
            <Button
              color="primary"
              variant="contained"
              className="routerLink-btn"
              component={RouterLink}
              to="/leave/apply"
            >
              APPLY
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="routerLink-btn mL10"
              component={RouterLink}
              to="/leave/approve"
            >
              APPROVE
            </Button>
          </div>
        </Grid>
      </Grid>
      <div className="tabContainer leaveTab">
        <Tabs
          value={value}
          onChange={handleChange}
          component="div"
          className="tabList tabPaneTable"
        >
          <Tab label="Leave" wrapped {...a11yProps(0)} />
          <Tab label="Attendance" {...a11yProps(1)} />

          <Tab
            label="Attendance History"
            {...a11yProps(2)}
            className="floatR"
          />
          <Tab label="Leave History" {...a11yProps(3)} className="floatR" />
          <Tab
            label={
              <span>
                My Team{" "}
                <ForwardSharpIcon style={{ "vertical-align": "middle" }} />
              </span>
            }
            {...a11yProps(4)}
            disabled
            className="floatR"
          />
        </Tabs>
        <TabPanel value={value} index={0} component="div" className="tabTable">
          <div className="leaveToolbar">
            <TableDateFilter
              handleFilterSubmit={handleAppliedLeaveFilterSubmit}
            />
          </div>

          <MaterialTable
            columns={[
              { title: "Employee Code", field: "empCode" },
              { title: "From", field: "from" },
              { title: "To", field: "to" },
              { title: "Leave Type", field: "leaveType" },
              { title: "Count", field: "count" },
              { title: "Reason", field: "reason" },
              {
                title: "Status",
                field: "status",
                cellStyle: rowData => {
                  let statusColor = "#4caf50";
                  if (rowData == "Approved") statusColor = "#264796";
                  if (rowData == "Rejected") statusColor = "#ff0000";
                  return {
                    color: statusColor
                  };
                }
              }
            ]}
            actions={[
              rowData => ({
                icon: "delete",
                tooltip: "Delete Leave",
                hidden: rowData.status !== "Pending",
                onClick: (e, rowData) => {
                  return deleteLeave(rowData.leaveId);
                }
              })
            ]}
            localization={{
              pagination: {
                labelRowsSelect: " "
              }
            }}
            options={{
              actionsColumnIndex: -1,
              searchFieldStyle: {
                border: "1px solid #ced4da",
                borderRadius: "5px",
                color: "#7c7c7c",
                padding: "0 5px 0 10px",
                width: "220px"
              }
            }}
            data={leavesTableData}
            title=""
          />
        </TabPanel>
        <TabPanel value={value} index={1} component="div" className="tabTable">
          <Attendance />
        </TabPanel>

        <TabPanel value={value} index={2} component="div" className="tabTable">
          <Attendance isHistory={true} />
        </TabPanel>
        <TabPanel value={value} index={3} component="div" className="tabTable">
          <LeaveHistory />
        </TabPanel>
        <TabPanel value={value} index={4} component="div" className="tabTable">
          jhnjbh
        </TabPanel>
      </div>
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={e => {
          setAlert({
            ...alert,
            open: false,
            class: "",
            message: ""
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        message={alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={e => {
                setAlert({
                  ...alert,
                  open: false,
                  class: "",
                  message: ""
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(LeaveDashboard);
