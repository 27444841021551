import React from "react";
// import Moment from "react-moment";
import moment from "moment";

const CustomDateFormat = props => {
  if (
    props.utc_date !== "undefined" &&
    moment(props.utc_date).isValid() &&
    props.isDay === true
  ) {
    return moment(props.utc_date).format("dddd");
  } else if (
    props.utc_date !== "undefined" &&
    moment(props.utc_date).isValid()
  ) {
    return moment(props.utc_date).format("DD-MMM-YYYY");
  } else {
    return "NA";
  }
};

export default CustomDateFormat;
