import React from "react";
import Login from "./pages/login/Login";
// import Dashboard from "./pages/dashboard/Dashboard";
import { Switch, Route } from "react-router-dom";
// import SidebarComponent from "./components/SidebarComponent";
import ProtectedRoute from "./components/ProtectedRoute";
import UnProtectedRoute from "./components/UnProtectedRoute";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LayoutStyles } from "./components/layout.style";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import EmployeeListing from "./pages/employee/EmployeeListing";
import EmployeeView from "./pages/employee/EmployeeView";
import EmployeeCreate from "./pages/employee/EmployeeCreate";
import PayStructure from "./pages/Payroll/PayStructure";
import ApplyLeaveForm from "./pages/leaves/ApplyLeave";
import Holidays from "./pages/holidays/holidays";
import PaySlip from "./pages/Payroll/PaySlip";
import RolePermission from "./pages/role/RolePermission";
import Roles from "./pages/role/Roles";
import Error from "./assets/images/error404.png";
import AccessDenied from "./assets/images/accessDenied.png";
import LeaveDashboard from "./pages/leaves/LeaveDashboard";
import LeaveRequest from "./pages/leaves/LeaveRequest";
import Attendance from "./pages/leaves/Attendance";
import Dashboard from "./pages/hrLeaveDashboard";
import EmployeeReportDashboard from "./pages/employee/EmployeeReportDashboard";
// import { isAuthorised } from "./utils/accessHelper";

function App(props) {
  const classes = LayoutStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!props.isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Sidebar open={open} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <UnProtectedRoute exact path="/login">
            <Login />
          </UnProtectedRoute>
          <ProtectedRoute exact path="/" component={EmployeeListing} />
          <ProtectedRoute
            exact
            path="/employees/add"
            permissions="employee_management.add"
            component={EmployeeCreate}
          />
          <ProtectedRoute exact path="/payStructure" component={PayStructure} />
          <ProtectedRoute exact path="/paySlip" component={PaySlip} />
          <ProtectedRoute exact path="/employees" component={EmployeeListing} />
          <ProtectedRoute
            exact
            path="/leave/apply"
            component={ApplyLeaveForm}
          />
          <ProtectedRoute
            exact
            path="/leave/dashboard"
            component={LeaveDashboard}
          />
          <ProtectedRoute
            exact
            path="/leave/approve"
            component={LeaveRequest}
          />
          <ProtectedRoute exact path="/holidays" component={Holidays} />
          <ProtectedRoute exact path="/attendance" component={Attendance} />
          <ProtectedRoute
            exact
            path="/hr/dashboard"
            component={Dashboard}
            permissions="hr_leave_dashboard.view"
          />

          <ProtectedRoute
            exact
            path="/roles/:roleId"
            permissions="role_permissions.edit"
            component={RolePermission}
          />
          <ProtectedRoute
            exact
            path="/employees/reports/dashboard/:type?"
            permissions="employee_report_dashboard.view"
            component={EmployeeReportDashboard}
          />
          <ProtectedRoute
            exact
            path="/roles"
            component={Roles}
            permissions="role_permissions.view"
          />
          <ProtectedRoute
            exact
            path="/employees/:profileId/view"
            permissions="employee_management.view"
            component={EmployeeView}
            selfActionPermission={true}
          />
          <ProtectedRoute
            exact
            path="/employees/:profileId/edit"
            permissions={[
              "employee_management.edit",
              "employee_management.add"
            ]}
            component={EmployeeCreate}
            selfActionPermission={true}
          />

          <Route exact path="/401" component={NotAllowed} />
          <Route path="*" component={NotFound404} />
        </Switch>
      </main>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const NotAllowed = function() {
  return (
    <div className="accessDenied">
      <img src={AccessDenied} alt="accessDeny" />
    </div>
  );
};

const NotFound404 = function() {
  return (
    <div className="errorPage">
      <img src={Error} alt="404" />
    </div>
  );
};

export default connect(mapStateToProps, null)(App);
