import React, { useState, useEffect } from "react";

import MaterialTable from "material-table";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";

let Attendance = props => {
  let [attendanceData, setAttendanceData] = useState([]);

  let apiScope = "my";

  async function getAttendanceData(filterParams = {}) {
    if (props.isHistory === true) apiScope = "team";
    if (props.showAll === true) apiScope = "all";
    filterParams["scope"] = apiScope;
    try {
      let { data } = await Axios.post("/api/leaves/all/timedata", filterParams);
      setAttendanceData(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(function() {
    getAttendanceData();
  }, []);

  let tableData = [];
  tableData = attendanceData.map(data => ({
    empCode: data.userProfile.employeeCode,
    empName: data.userProfile.user.name,
    date: CustomDateFormat({ utc_date: data.dateOffice, isDay: false }),
    day: CustomDateFormat({ utc_date: data.dateOffice, isDay: true }),
    officeIn: data.in1,
    officeOut: data.out1,
    status: data.dayStatus,
    hours: data.hourWorked
  }));

  function handleAttendanceFilterSubmit(params) {
    let filterParams = params;
    getAttendanceData(filterParams);
  }

  return (
    <>
      <div className="leaveToolbar">
        <TableDateFilter handleFilterSubmit={handleAttendanceFilterSubmit} />
      </div>
      <MaterialTable
        columns={[
          { title: "Emp. Code", field: "empCode" },
          {
            title: "Emp. Name",
            field: "empName",
            cellStyle: { textTransform: "capitalize" }
          },
          { title: "Date", field: "date" },
          { title: "Day", field: "day" },
          { title: "Office In", field: "officeIn" },
          { title: "Office Out", field: "officeOut" },
          {
            title: "Status",
            field: "status",
            cellStyle: rowData => {
              let color = "#264796";
              if (rowData === "A") color = "#ff0000";
              if (rowData === "P") color = "#4caf50";
              return { color: color };
            }
          },
          { title: "Hours of Work", field: "hours" }
        ]}
        options={{
          searchFieldStyle: {
            border: "1px solid #ced4da",
            borderRadius: "5px",
            color: "#7c7c7c",
            padding: "0 5px 0 10px",
            width: "220px"
          },
          sorting: true
        }}
        data={tableData}
        title=""
      />
    </>
  );
};

export default Attendance;
