import React from "react";
import addEmployeeValidationSchema from "../../../validation/employees/addEmployeeValidation";
import {
  Grid,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { getIn, ErrorMessage, FieldArray, withFormik } from "formik";
import clsx from "clsx";
import moment from "moment";

function medicalForm(props) {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    isEditable
  } = props;
  console.log(values);
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <Grid container className="xs-pl15 pT15 pB15" spacing={4}>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Insurance Provider* </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter Insurance Provider"
                {...getFieldProps("medical.insuranceProvider")}
                name={"medical.insuranceProvider"}
                value={values.medical.insuranceProvider || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name="medical.insuranceProvider"
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>TPA </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter TPA"
                name={"medical.tpa"}
                {...getFieldProps("medical.tpa")}
                value={values.medical.tpa || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name={`medical.tpa`}
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>TPA ID/UHID </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter TPA id"
                name={"medical.tpaId"}
                {...getFieldProps("medical.tpaId")}
                value={values.medical.tpaId || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name={`medical.tpaId`}
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Policy Number</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter policy number"
                name={"medical.policyNumber"}
                {...getFieldProps("medical.policyNumber")}
                value={values.medical.policyNumber || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name={`medical.policyNumber`}
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Sum Assured </label>
              <input
                type="number"
                className="form-input"
                placeholder="Sum assured"
                name={"medical.sumAssured"}
                {...getFieldProps("medical.sumAssured")}
                value={values.medical.sumAssured || ""}
                disabled={!isEditable}
              />
              <ErrorMessage
                component="p"
                name={`medical.sumAssured`}
                className="error-text"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label className="mB15">Policy Type </label>
              <RadioGroup aria-label="position" name="position" row>
                <FormControlLabel
                  className="cssCheck"
                  control={<Radio color="primary" />}
                  label="Individual"
                  {...getFieldProps("medical.policyType")}
                  name="medical.policyType"
                  labelPlacement="end"
                  value="Individual"
                  checked={values.medical.policyType === "Individual"}
                  disabled={!isEditable}
                />
                <FormControlLabel
                  className="cssCheck"
                  control={<Radio color="primary" />}
                  label="Floater"
                  {...getFieldProps("medical.policyType")}
                  name="medical.policyType"
                  labelPlacement="end"
                  value="Floater"
                  checked={values.medical.policyType === "Floater"}
                  disabled={!isEditable}
                />
              </RadioGroup>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Start Date </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "medical.startDate") &&
                      getIn(touched, "medical.startDate") &&
                      "error"
                  )}
                  value={values.medical.startDate}
                  name="medical.startDate"
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue("medical.startDate", moment(e).toISOString());
                  }}
                  disabled={!isEditable}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>End Date </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  placeholder="Select date"
                  label={false}
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  className={clsx(
                    "picker-input",
                    getIn(errors, "medical.endDate") &&
                      getIn(touched, "medical.endDate") &&
                      "error"
                  )}
                  value={values.medical.endDate}
                  name="medical.endDate"
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue("medical.endDate", moment(e).toISOString());
                  }}
                  disabled={!isEditable}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} className="formContainer">
            <div className="input-group">
              <label>Medical Insurance Status </label>
              <select
                className="form-select"
                name="medical.status"
                {...getFieldProps("medical.status")}
                value={(values.medical && values.medical.status) || ""}
                disabled={!isEditable}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Deactive">Deactive</option>
              </select>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Grid container className="xs-pl15" spacing={4}>
              <FieldArray
                name="medical.familyMembers"
                render={arrayHelpers => (
                  <React.Fragment>
                    <Grid item xs={12} className="mB15">
                      <Button
                        variant="outlined"
                        onClick={e => {
                          arrayHelpers.push({
                            name: "",
                            relationship: "",
                            status: ""
                          });
                        }}
                        disabled={!isEditable}
                      >
                        + Add Family Member
                      </Button>
                    </Grid>
                    {values.medical.familyMembers &&
                    values.medical.familyMembers.length > 0 ? (
                      values.medical.familyMembers.map(
                        (familyMember, index) => (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              className="mB25"
                            >
                              <Grid item xs={11} className="formContainer">
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group">
                                      <label>Name </label>
                                      <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Enter name"
                                        name={`medical.familyMembers[${index}].name`}
                                        {...getFieldProps(
                                          `medical.familyMembers[${index}].name`
                                        )}
                                        value={
                                          values.medical.familyMembers[index]
                                            .name || ""
                                        }
                                        disabled={!isEditable}
                                      />
                                      <ErrorMessage
                                        component="p"
                                        name={`medical.familyMembers[${index}].name`}
                                        className="error-text"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer pR0"
                                  >
                                    <div className="input-group">
                                      <label className="mB15">Gender </label>
                                      <RadioGroup
                                        aria-label="position"
                                        name="position"
                                        row
                                      >
                                        <FormControlLabel
                                          className="cssCheck"
                                          control={<Radio color="primary" />}
                                          label="Male"
                                          {...getFieldProps(
                                            `medical.familyMembers[${index}].gender`
                                          )}
                                          name={`medical.familyMembers[${index}].gender`}
                                          labelPlacement="end"
                                          value="Male"
                                          checked={
                                            values.medical.familyMembers[index]
                                              .gender === "Male"
                                          }
                                          disabled={!isEditable}
                                        />
                                        <FormControlLabel
                                          className="cssCheck"
                                          control={<Radio color="primary" />}
                                          label="Female"
                                          {...getFieldProps(
                                            `medical.familyMembers[${index}].gender`
                                          )}
                                          name={`medical.familyMembers[${index}].gender`}
                                          labelPlacement="end"
                                          value="Female"
                                          checked={
                                            values.medical.familyMembers[index]
                                              .gender === "Female"
                                          }
                                          disabled={!isEditable}
                                        />
                                      </RadioGroup>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group">
                                      <label>DOB </label>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <KeyboardDatePicker
                                          
                                          variant="inline"
                                          format="dd/MM/yyyy"
                                          margin="normal"
                                          id="date-picker-dialog"
                                          placeholder="Select date"
                                          label={false}
                                          autoOk={true}
                                          KeyboardButtonProps={{
                                            "aria-label": "change date"
                                          }}
                                          className="picker-input"
                                          value={
                                            values.medical.familyMembers[index]
                                              .dob
                                          }
                                          name={`medical.familyMembers[${index}].dob`}
                                          onBlur={handleBlur}
                                          onChange={e => {
                                            setFieldValue(
                                              `medical.familyMembers[${index}].dob`,
                                              moment(e).toISOString()
                                            );
                                          }}
                                          disabled={!isEditable}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group">
                                      <label>Relationship </label>
                                      <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Enter relationship"
                                        name={`medical.familyMembers[${index}].relationship`}
                                        {...getFieldProps(
                                          `medical.familyMembers[${index}].relationship`
                                        )}
                                        value={
                                          values.medical.familyMembers[index]
                                            .relationship || ""
                                        }
                                        disabled={!isEditable}
                                      />
                                      <ErrorMessage
                                        component="p"
                                        name={`medical.familyMembers[${index}].relationship`}
                                        className="error-text"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group">
                                      <label>TPA ID/UHID </label>
                                      <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Enter TPA id"
                                        name={
                                          `medical.familyMembers[${index}].tpaId`
                                        }
                                        {...getFieldProps(
                                          `medical.familyMembers[${index}].tpaId`
                                        )}
                                        value={
                                          values.medical.familyMembers[index]
                                            .tpaId || ""
                                        }
                                        disabled={!isEditable}
                                      />
                                      <ErrorMessage
                                        component="p"
                                        name={`medical.familyMembers[${index}].tpaId`}
                                        className="error-text"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group">
                                      <label>Status </label>
                                      <select
                                        className="form-select"
                                        {...getFieldProps(
                                          `medical.familyMembers[${index}].status`
                                        )}
                                        value={
                                          values.medical.familyMembers[index]
                                            .status || ""
                                        }
                                        disabled={!isEditable}
                                      >
                                        <option value="">Select</option>
                                        <option key="1" value="active">
                                          Active
                                        </option>
                                        <option key="2" value="deactive">
                                          Deactive
                                        </option>
                                      </select>
                                      <ErrorMessage
                                        component="p"
                                        name={`medical.familyMembers[${index}].status`}
                                        className="error-text"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className="formContainer">
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={6}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="formContainer"
                                  >
                                    <div className="input-group btnConatinerR floatL">
                                      <label>&nbsp;</label>
                                      <Button
                                        className="removeBtn mT8"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        disabled={!isEditable}
                                      >
                                        -
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Grid item xs={12} className="warning-msg">
                        No family member has been added.
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>

          {isEditable && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className="formContainer"
            >
              <div className="permissionBtn pL0">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="mL20"
                  onClick={props.resetForm}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const formikMedicalForm = withFormik({
  // mapping form field values for this form
  mapPropsToValues: ({ formData }) => {
    let initialValues = {
      userProfileId: formData._id,
      medical: formData.medical
        ? formData.medical
        : { insuranceProvider: "", sumAssured: "" }
    };
    initialValues.medical.familyMembers =
      initialValues.medical.familyMembers || [];

    return initialValues;
  },
  //adding validation schema
  validationSchema: addEmployeeValidationSchema.medical,
  enableReinitialize: true,

  handleSubmit: (values, formikBag) => {
    values.medical = values.medical || null;
    if (formikBag.props.submitHandler) {
      formikBag.props.submitHandler(values, formikBag);
    } else {
      console.log("Submit handler not provided");
    }
  },

  displayName: "Medical"
})(medicalForm);

export default formikMedicalForm;
