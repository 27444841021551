import React from "react";
import MaterialTable from "material-table";
import CustomBreadcrumb from "../../components/breadcrumb";
import axios from "axios";

import CustomDateFormat from "../../components/DateFormat";

import { Typography, Button, Grid, Paper } from "@material-ui/core";
import { Link as RouterLink, Link, NavLink } from "react-router-dom";
import TotalEmployee from "./../../assets/images/icon-02.svg";
import AvgYear from "./../../assets/images/icon-04.svg";
import AvgAge from "./../../assets/images/icon-03.svg";
import AddedIcon from "./../../assets/images/icon-01.svg";
import "./EmployeeList.scss";
import { isAuthorised } from "../../utils/accessHelper";
import { dateSortAlgo } from "../../utils/manipulate";
import { CsvBuilder } from "filefy";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';

const ReactHtmlParserDocuments=(documents)=>{
  try {
    let textData = "";
    {documents && documents.map((documentName) => {
      textData += documentName.category.shortName ? `<a href='${axios.defaults.baseURL}/${documentName.file}' target="_blank">${documentName.category.shortName}</a>` + "<br>" : "NA" + "<br>";
    })}
    return ReactHtmlParser(textData);

  } catch (err) {
    //console.log(err);
  }
};

class EmployeeReportDashboardComponent extends React.Component {
  state = {
    isLoading: true,
    employees: [],
    employeeStats: [],
    tableprops: {},
    currentPage: 0,
    rowPerpage: 190,
    index: 1,
    tabs: []
  };

  async updateContent(ptype) {
    try {
      this.setState({ isLoaded: false });
      let response = await axios.post("/users/view/report/data/" + ptype);
      this.setState({
        employees: response.data,
        pageType: ptype,
        isLoading: false,
        currentPage: 0,
        index: 1
      });
    } catch (err) {
      //console.log(err);
    }
    this.setState({ isLoaded: true });
  }

  async updateContentDownload(documentCategory,name) {
    try {
      this.setState({ isLoaded: false });
      let response = await axios.get("/stats/document/downloads/" + documentCategory, {
        responseType: 'arraybuffer'
      });
      const data = new Blob([response.data], {"type": "octet/stream"})
      const csvURL = window.URL.createObjectURL(data)
      let tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.download = `${name}.zip`
      tempLink.click()
    } catch (err) {
      //console.log(err);
    }
    this.setState({ isLoaded: true });
  }

  async componentDidMount() {
    let { params } = this.props.match;
    let pageType = params.type ? params.type : null;
    try {
      let response = await axios.post("/users/view/report/data/personal");
      let tabs = await axios.get(`/masters/document-category-list`);
      this.setState({
        employees: response.data,
        pageType: pageType,
        isLoading: false,
        tabs: tabs.data.data
      });
    } catch (err) {
      //console.log(err);
    }

    this.setState({ isLoaded: true });
  }

  render() {
    let { isLoading, employees, isLoaded, pageType, index, tabs } = this.state;
    // let { history } = this.props;
    if (!isLoaded) {
      return (
        <React.Fragment>
          <div className="loader"></div>
          <div className="loaderLogo"></div>
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ maxWidth: "100%" }} className="tableTitle">
          <h1>Employees</h1>

          <div className="oflowH mB15">
            <CustomBreadcrumb
              data={[
                { heading: "Dashboard", link: "/" },
                {
                  heading: "Employees Reports Dashboard",
                  link: "/employees/reports/dashboard",
                },
              ]}
            />
            <div className="btnConatinerR">
              {isAuthorised("employee_management.add") ? (
                <Button
                  color="primary"
                  variant="contained"
                  component={RouterLink}
                  to="/employees/add"
                >
                  + Add Employee
                </Button>
              ) : null}

              {/* <Button variant="contained" color="primary">
              + Add Employee
            </Button> */}
            </div>
          </div>
          <Grid container spacing={2} className="mB15"></Grid>
          <div className="table_group-btns">
            <ul>
              <li>
                <NavLink to="/employees/reports/dashboard/personal" activeClassName="active" onClick={() => this.updateContent('personal')}>Personal</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/contact" activeClassName="active" onClick={() => this.updateContent('contact')}>Contact</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/education" activeClassName="active" onClick={() => this.updateContent('education')}>Education</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/training" activeClassName="active" onClick={() => this.updateContent('training')}>Training</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/skills" activeClassName="active" onClick={() => this.updateContent('skills')}>Skills</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/certificates" activeClassName="active" onClick={() => this.updateContent('certificates')}>Certificates</NavLink>
              </li>
              <li>
                <NavLink to="/employees/reports/dashboard/job-history" activeClassName="active" onClick={() => this.updateContent('job-history')}>Job-History</NavLink>
              </li>
            </ul>
          </div>
          <div className="table_group-btns btn-card">
            <Grid container className="main-row">

                <h4>Download</h4>
              <Grid container className="btn-grid">
              {tabs && tabs.map((tab) => {
                return (
                  <Grid item >
                    <Button onClick={() => this.updateContentDownload(tab._id,tab.shortName)}>{tab.shortName}</Button>
                  </Grid>

                )
              })}
              </Grid>

            </Grid>
            {/* <ul>
              {tabs && tabs.map((tab) => {
                return (
                  <li>
                    <div>
                      <Button onClick={() => this.updateContentDownload(tab._id)}>{tab.shortName}</Button>
                    </div>
                  </li>
                )
              })}
            </ul> */}
          </div>
          <MaterialTable
            localization={{
              pagination: {
                labelRowsSelect: " ",
              },
            }}
            options={{
              actionsColumnIndex: 9,
              pageSize: this.state.rowPerpage,
              pageSizeOptions: (() => {
                let options = [10, 20, 50, 100].filter(
                  (option) => this.state.employees.length > option
                );
                return options;
              })(),
              exportButton: true,
              /* exportCsv : (columnList, initialData) => {
                console.log(columnList);
                const columns = columnList.filter(columnDef => {
                  return !columnDef.hidden && columnDef.field && columnDef.export !== false;
                });
                
                const data = initialData.map(rowData =>
                  columns.map(columnDef => {
                    //return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
                    let pfi = columnDef.field;
                    console.log(rowData[{pfi}]);
                    return rowData[columnDef.field];
                  })
                );
                const builder = new CsvBuilder('data' + '.csv');
                builder
                  .setDelimeter(',')
                  .setColumns(columns.map(columnDef => columnDef.title))
                  .addRows(data)
                  .exportFile();
                  return;
              }, */
              searchFieldStyle: {
                border: "1px solid #ced4da",
                borderRadius: "5px",
                color: "#7c7c7c",
                padding: "0 5px 0 10px",
                width: "220px",
              },
            }}
            onChangeRowsPerPage={(data) => {
              this.setState({ rowPerpage: data, index: 1 });
              //this.index = 1;
            }}
            onChangePage={(page, row) => {
              let indexPage = page * this.state.rowPerpage + 1;
              this.setState({ currentPage: page, index: indexPage });
            }}
            onOrderChange={() => {
              this.setState({ currentPage: 0, index: 1 });
              //this.index = 1;
            }}
            isLoading={isLoading}
            actions={[]}
            columns={(() => {
              let tableColumn = [];

              // Personal Tab
              if (pageType == "personal") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },

                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeCode",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  {
                    title: "Name", field: "user.name",
                    render: (rowData) => (
                      <Link to={{ pathname: `/employees/${rowData._id}/view`, state: { prevPath: 'employeeReport' } }} style={{ textDecoration: "none" }}>{rowData.user.name}</Link>
                    ),
                  },
                  //{ title: "Gender", field: "gender",sorting: false },
                  { title: "Contact", field: "contactNumber[0]", sorting: false },
                  { title: "Designation", field: "designation.title" },
                  { title: "Department", field: "department.title" },
                  {
                    title: "Email", field: "user.email",
                    render: (rowData) => (
                      <a href={`mailto: ${rowData.user.email}`} style={{ textDecoration: "none" }}>{rowData.user.email}</a>
                    ),
                    sorting: false
                  },
                  {
                    title: "Date of Joining",
                    field: "ModifyDOJ",
                    sorting: true,
                    cellStyle: {
                      width: "160px",
                    },

                    customSort: (a, b) => {
                      return dateSortAlgo(a.dateOfJoining, b.dateOfJoining);
                    },
                    render: (rowData) =>
                      rowData.dateOfJoining && (
                        <CustomDateFormat utc_date={rowData.dateOfJoining} />
                      ),
                  },
                  {
                    title: "Reporting To",
                    field: "reportingPerson.name",
                    sorting: false,
                  }
                  // {
                  //   title: "Documents",
                  //   field: "documents",
                  //   sorting: false,
                  //   render: (rowData) => { return ReactHtmlParserDocuments(rowData.documents) }
                  // }

                );
              }
              // End of Personal Tab
              // Contact Tab
              if (pageType == "contact") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name" },
                  //{ title: "Gender", field: "gender",sorting: false },
                  {
                    title: "Present Address",
                    field: "presentAddress", // matters for grouping and sorting
                    render: (rowData) => { return ReactHtmlParser(rowData.presentAddress) }
                  },
                  {
                    title: "Permanent Address",
                    field: "permanentAddress", // matters for grouping and sorting
                    render: (rowData) => { return ReactHtmlParser(rowData.permanentAddress) }
                  }
                );
              }
              // End of Contact Tab

              // education Tab
              if (pageType == "education") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name", cellStyle: { width: "12%" } },
                  { title: "Degree", field: "degree", cellStyle: { width: "17%" }, render: (rowData) => { return ReactHtmlParser(rowData.degree) } },
                  { title: "Subject", field: "subject", render: (rowData) => { return ReactHtmlParser(rowData.subject) } },
                  { title: "Passing Year", field: "passingYear", render: (rowData) => { return ReactHtmlParser(rowData.passingYear) } },
                  { title: "Institute", field: "institute", cellStyle: { width: "24%" }, render: (rowData) => { return ReactHtmlParser(rowData.institute) } }
                );
              }
              // End of education Tab

              // training Tab
              if (pageType == "training") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name" },
                  //{ title: "Gender", field: "gender",sorting: false },
                  { title: "Program Name", field: "program_name", render: (rowData) => { return ReactHtmlParser(rowData.program_name) } },
                  { title: "Conducted By", field: "conducted_by", render: (rowData) => { return ReactHtmlParser(rowData.conducted_by) } },
                  { title: "Year", field: "year", render: (rowData) => { return ReactHtmlParser(rowData.year) } },
                  { title: "Duration", field: "duration", render: (rowData) => { return ReactHtmlParser(rowData.duration) } }
                );
              }
              // End of training Tab

              // skills Tab
              if (pageType == "skills") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name" },
                  //{ title: "Gender", field: "gender",sorting: false },
                  { title: "Skill", field: "skill", render: (rowData) => { return ReactHtmlParser(rowData.skill) } },
                  { title: "Technology", field: "technology", render: (rowData) => { return ReactHtmlParser(rowData.technology) } },
                  { title: "Year Of Exp", field: "year_of_exp", render: (rowData) => { return ReactHtmlParser(rowData.year_of_exp) } },
                  { title: "Competency Level", field: "competency_level", render: (rowData) => { return ReactHtmlParser(rowData.competency_level) } },
                  { title: "Last Used", field: "last_used", render: (rowData) => { return ReactHtmlParser(rowData.last_used) } }
                );
              }
              // End of skills Tab

              // certificates Tab
              if (pageType == "certificates") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name" },
                  { title: "Certificate Name", field: "certificate_name", render: (rowData) => { return ReactHtmlParser(rowData.certificate_name) } },
                  { title: "Year Of Certification", field: "year_of_certification", render: (rowData) => { return ReactHtmlParser(rowData.year_of_certification) } },
                  { title: "Institute Name", field: "institute_name", render: (rowData) => { return ReactHtmlParser(rowData.institute_name) } }
                );
              }
              // End of certificates Tab

              // job-history Tab
              if (pageType == "job-history") {
                tableColumn.push(
                  {
                    title: "",
                    field: "tableData.id",
                    sorting: false,
                    //dataKey:"serial",
                    render: (rowData) => {
                      if (
                        this.state.employees.length < this.state.rowPerpage &&
                        this.state.index > this.state.employees.length
                      ) {
                        this.state.index = 1;
                      }
                      if (
                        this.state.index >
                        this.state.rowPerpage * this.state.currentPage +
                        this.state.rowPerpage
                      ) {
                        this.state.index =
                          this.state.rowPerpage * this.state.currentPage + 1;
                      }
                      return this.state.index++;
                    },
                    cellStyle: {
                      width: "50px",
                    },
                  }, //tableData.id
                  {
                    title: "ID",
                    field: "employeeId",
                    defaultSort: "asc",
                    //sorting: false,
                    cellStyle: {
                      width: "70px",
                    },
                  },
                  { title: "Name", field: "name" },
                  { title: 'From Date', field: 'from_date', render: (rowData) => { return ReactHtmlParser(rowData.from_date) } },
                  { title: 'To Date', field: 'to_date', render: (rowData) => { return ReactHtmlParser(rowData.to_date) } },
                  { title: 'Organization Name', field: 'organization_name', render: (rowData) => { return ReactHtmlParser(rowData.organization_name) } },
                  { title: 'Last Position', field: 'last_position', render: (rowData) => { return ReactHtmlParser(rowData.last_position) } },
                  { title: 'Hr Name', field: 'HRName', render: (rowData) => { return ReactHtmlParser(rowData.HRName) } },
                  { title: 'Hr Contact', field: 'HRContact', render: (rowData) => { return ReactHtmlParser(rowData.HRContact) } },
                  { title: 'Hr Email', field: 'HREmail', render: (rowData) => { return ReactHtmlParser(rowData.HREmail) } }
                );
              }
              // End of job-history Tab

              return tableColumn;
            })()}
            data={employees}
            components={
              {
                // OverlayLoading: props => <LinearProgress />
              }
            }
            title={null}
          />
        </div>
      );
    }
  }
}

export default EmployeeReportDashboardComponent;
