import React from "react";
import Logo from "./../../assets/images/logo.png";
import SigninBtn from "./../../assets/images/sign-in-btn.png";
import "./Login.scss";
import { connect } from "react-redux";
import { GOOGLE_AUTH } from "../../constants";
import { setErrors } from "../../actions/errorActions";
import { authLogin } from "../../actions/authActions";
import { withRouter } from "react-router-dom";
import GoogleLogin from "react-google-login";
import Axios from "axios";

class Login extends React.Component {
  state = {
    message: null
  };

  responseGoogle = response => {
    this.setState({ message: null });
    let { profileObj } = response;
    Axios.post("/auth/google", profileObj)
      .then(({ data }) => {
        this.props.authLogin(data);
      })
      .catch(err => {
        let response = err.response;
        if (response.data && response.data.message)
          this.setState({ message: response.data.message });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="thumbnail-box">
          <div className="thumbnail-row">
            <div className="thumbnail"></div>
          </div>
          <div className="thumbnail-row">
            <div className="thumbnail thumbnail-second"></div>
          </div>
          <div className="thumbnail-row">
            <div className="thumbnail thumbnail-third"></div>
          </div>
          <div className="thumbnail-row">
            <div className="thumbnail thumbnail-fourth"></div>
          </div>
          <div className="thumbnail-row">
            <div className="thumbnail thumbnail-fifth"></div>
          </div>
          <div className="thumbnail-row">
            <div className="thumbnail thumbnail-sixth"></div>
          </div>
          <div className="login-overlay"></div>
        </div>

        <div className="welcome">
          <p>
            Welcome back!
            <br />
            Please login to your account
            <span>HR / Manager / CEO / Super Admin / Account / Employee</span>
          </p>
        </div>
        <div className="login-form">
          <figure>
            <img src={Logo} alt="Logo" />
          </figure>
          <GoogleLogin
            clientId={GOOGLE_AUTH.CLIENT_ID}
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={err => console.log(err)}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img
                  src={SigninBtn}
                  alt="Sign In"
                  // onClick={this.loginHandler}
                />
              </button>
            )}
            cookiePolicy={"single_host_origin"}
          />
          <p>Use your Netprophets Official account.</p>
          {this.state.message && (
            <p className="errorMsg">{this.state.message}</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

// mapping state to props
function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

// mapping state to props
function mapDispatchToProps(dispatch) {
  return {
    authLogin: user => {
      dispatch(authLogin(user));
    },
    setErrors: errs => {
      dispatch(setErrors(errs));
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
