import React, { useState, useEffect } from "react";
import moment from "moment";
import MaterialTable from "material-table";

import Axios from "axios";
import TableDateFilter from "../../components/TableDateFilter";
import CustomDateFormat from "../../components/DateFormat";

export default function LeaveHistory(props) {
  let [leaveHistory, setLeaveHistory] = useState([]);
  let apiScope = "team";

  async function getLeaveHistory(filterParams = {}) {
    if (props.showAll === true) apiScope = "all";
    filterParams["scope"] = apiScope;
    try {
      let { data } = await Axios.post("/api/leaves/all/leaves", filterParams);
      setLeaveHistory(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(function() {
    getLeaveHistory();
  }, []);

  let tableData = [];

  tableData = leaveHistory.map(leave => ({
    empCode: leave.userProfile.employeeCode,
    empName: leave.userProfile.user.name,
    leaveType: leave.leaveType.title,
    from: `${CustomDateFormat({ utc_date: leave.startDate, isDay: true })} 
    ${CustomDateFormat({ utc_date: leave.startDate, isDay: false })}`,
    to: `${CustomDateFormat({ utc_date: leave.endDate, isDay: true })} 
${CustomDateFormat({ utc_date: leave.endDate, isDay: false })}`,
    leaveDay: leave.noOfDays,
    approveStatus: leave.status,
    reason: leave.reason,
    leaveId: leave._id
  }));

  function handleLeaveHistoryFilterSubmit(params) {
    let filterParams = params;
    getLeaveHistory(filterParams);
  }

  return (
    <>
      <div className="leaveToolbar">
        <TableDateFilter handleFilterSubmit={handleLeaveHistoryFilterSubmit} />
      </div>
      <MaterialTable
        columns={[
          { title: "Employee Code", field: "empCode" },
          {
            title: "Employee Name",
            field: "empName",
            cellStyle: {
              "text-transform": "capitalize"
            }
          },
          { title: "Leave Type", field: "leaveType" },
          { title: "From", field: "from" },
          { title: "To", field: "to" },
          { title: "Leave Day", field: "leaveDay" },
          {
            title: "Approval Status",
            field: "approveStatus",
            cellStyle: rowData => {
              let statusColor = "#4caf50";
              if (rowData === "Approved") statusColor = "#264796";
              if (rowData === "Rejected") statusColor = "#ff0000";
              return {
                color: statusColor
              };
            }
          },
          { title: "Reason", field: "reason" }
        ]}
        localization={{
          pagination: {
            labelRowsSelect: " "
          }
        }}
        options={{
          searchFieldStyle: {
            border: "1px solid #ced4da",
            borderRadius: "5px",
            color: "#7c7c7c",
            padding: "0 5px 0 10px",
            width: "220px"
          }
        }}
        data={tableData}
        title=""
      />
    </>
  );
}
