import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { LayoutStyles } from "./layout.style";
import ListItemLink from "../components/ListItemLink";
import { isAuthorised } from "../utils/accessHelper";
import LeaveIcon from "../assets/images/leaves.svg";
import HolidayIcon from "../assets/images/holiday.svg";


const SidebarComponent = props => {
  const { open } = props;
  const classes = LayoutStyles();
  const [menuOpen, setmenuOpen] = React.useState(false);

  // const handleClick = () => {
  //   setmenuOpen(!menuOpen);
  // };

  const navigations = [
    // {
    //   title: "Dashboard",
    //   link: "/",
    //   icon: <DashboardIcon />
    // },
    {
      title: " Employees",
      link: "/employees",
      icon: <PersonRoundedIcon />
    },
    {
      title: " Employees Reports",
      link: "/employees/reports/dashboard/personal",
      permissions: "employee_report_dashboard.view",
      icon: <ListAltOutlined />
    },
    {
      title: " Holidays",
      link: "/holidays",
      icon: <img src={HolidayIcon} alt="Calendar Icon" className="drawerIcon"/>
    }
    // {
    //   title: " Add Employee",
    //   link: "/employees/add"
    // },
    // {
    //   title: " Pay Structure",
    //   link: "/payStructure",
    //   icon: <ReceiptIcon />
    // },
    /* {
      title: " Role Permission",
      link: "/roles",
      permissions: "role_permissions.view",
      icon: <RadioButtonCheckedIcon />
    },
    {
      title: " Leaves",
      link: "/leave/dashboard",
      icon: <img src={LeaveIcon} alt="Calendar Icon" className="drawerIcon"/>
    },
    {
      title: " Holidays",
      link: "/holidays",
      icon: <img src={HolidayIcon} alt="Calendar Icon" className="drawerIcon"/>
    },
    {
      title: " HR Leave Dashboard",
      link: "/hr/dashboard",
      icon: <img src={HolidayIcon} alt="Calendar Icon" className="drawerIcon"/>
    } */
  ];

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
    >
      <div className={classes.toolbar}></div>
      <Divider />
      <List disablePadding>
        {navigations
          .filter((item, index) => isAuthorised(item.permissions))
          .map((item, index) => (
            <ListItemLink
              key={"listItem" + index}
              to={item.link}
              primary={item.title}
              icon={item.icon}
            />
          ))}
      </List>

      {/* Sidebar dropdown Item */}

      {/* <List className="sidebarDropdown" disablePadding
        style={{ display: "block" }}  
      >
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItem>
          </List>
        </Collapse>
      </List> */}
    </Drawer>
  );
};

export const Sidebar = SidebarComponent;
